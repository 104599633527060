import { useEtfStore } from "core/stores/Etf"
import type { RequestJson } from "./request/requestBase"
import { locale, t } from "core/service/LocaleService"

export interface Statistics {
  totalReturn: number
  lastReturn: number
  mtd: number
  ytd: number
  cagr: number
  volatility: number
  sharpe: number
  sortino: number
  upi: number
  mdd: number
  bestMonth: number
  worstMonth: number
  positiveMonths: string
  mddDate: string
  annualTurnover: number
  y1Return?: number
  y3Return?: number
  y5Return?: number
  y1Vol?: number
  y3Vol?: number
  y5Vol?: number
  equity_ratio: number
  bond_ratio: number
  alternatives_ratio: number
}

export class Statistics implements Statistics {
  constructor({
    total_return = 0,
    last_return = 0,
    mtd = 0,
    ytd = 0,
    cagr = 0,
    volatility = 0,
    sharpe = 0,
    sortino = 0,
    upi = 0,
    mdd = 0,
    best_month = 0,
    worst_month = 0,
    positive_months = '',
    mdd_date = '',
    annual_turnover = 0,
    y1_return = undefined,
    y3_return = undefined,
    y5_return = undefined,
    y1_vol = undefined,
    y3_vol = undefined,
    y5_vol = undefined,
    equity_ratio = 0,
    bond_ratio = 0,
    alternatives_ratio = 0,
  } = {}) {
    this.totalReturn = total_return
    this.lastReturn = last_return
    this.mtd = mtd
    this.ytd = ytd
    this.cagr = cagr
    this.volatility = volatility
    this.sharpe = sharpe
    this.sortino = sortino
    this.upi = upi
    this.mdd = mdd
    this.bestMonth = best_month
    this.worstMonth = worst_month
    this.positiveMonths = positive_months
    this.mddDate = mdd_date.slice(0, mdd_date.length - 3)
    this.annualTurnover = annual_turnover
    this.y1Return = y1_return
    this.y3Return = y3_return
    this.y5Return = y5_return
    this.y1Vol = y1_vol
    this.y3Vol = y3_vol
    this.y5Vol = y5_vol
    this.equity_ratio = equity_ratio
    this.bond_ratio = bond_ratio
    this.alternatives_ratio = alternatives_ratio
  }
}

export interface Strategy {
  url: string
  ord: number
  uuid: string
  nameEng: string
  nameShort: string
  stat10y?: Statistics
  stat20y?: Statistics
  statistics?: Statistics
  group: string
  category: string
  description: string
  feature: string
  rule?: string
  profileImg: string
  correlation: any
  offensive?: string
  aggression?: number
  avoid?: number
  tag?: string[]
  rebalancedEtf?: string[]
  replicable?: boolean
}

function getPortion(arr: Array<[string, number]>, category: string) {
  const etfStore = useEtfStore()

  const reducer = (previousValue: number, [ticker, weight]: [string, number]) => {
    const etf = etfStore.getEtf(ticker)
    if (etf && etf.group == category) {
      return previousValue + weight
    } else {
      return previousValue
    }
  }
  return arr.reduce(reducer, 0)
}

export class Strategy implements Strategy {
  constructor({
    url = '',
    uuid = '',
    group = '',
    category = '',
    short_name = '',
    short_name_ko = '',
    name_eng = '',
    description = '',
    feature = '',
    rule = undefined,
    profileImg = '',
    stat_10y = undefined,
    stat_20y = undefined,
    statistics = undefined,
    aggression = undefined,
    avoid = undefined,
    offensive = '',
    tag = undefined,
    rebalanced_etf = undefined,
    replicable = false,
  } = {}) {
    this.url = url
    this.uuid = uuid
    this.group = group
    this.category = category
    this.nameShort = locale == 'en' ? short_name : short_name_ko
    this.nameEng = name_eng
    this.description = description
    this.feature = feature
    this.rule = rule
    this.profileImg = profileImg

    if (statistics) {
      const stat: any = statistics as any
      if (rebalanced_etf) {
        stat['equity_ratio'] = getPortion(rebalanced_etf, t('common.equity'))
        stat['bond_ratio'] = getPortion(rebalanced_etf, t('common.bond'))
        stat['alternatives_ratio'] = getPortion(rebalanced_etf, t('common.alternative'))
      }
      this.statistics = new Statistics(stat)
    } else {
      this.statistics = undefined
    }

    if (stat_10y) {
      const stat: any = stat_10y as any
      if (rebalanced_etf) {
        stat['equity_ratio'] = getPortion(rebalanced_etf, t('common.equity'))
        stat['bond_ratio'] = getPortion(rebalanced_etf, t('common.bond'))
        stat['alternatives_ratio'] = getPortion(rebalanced_etf, t('common.alternative'))
      }
      this.stat10y = new Statistics(stat)
    } else {
      this.stat10y = undefined
    }

    if (stat_20y) {
      const stat: any = stat_20y as any
      if (rebalanced_etf) {
        stat['equity_ratio'] = getPortion(rebalanced_etf, t('common.equity'))
        stat['bond_ratio'] = getPortion(rebalanced_etf, t('common.bond'))
        stat['alternatives_ratio'] = getPortion(rebalanced_etf, t('common.alternative'))
      }
      this.stat20y = new Statistics(stat)
    } else {
      this.stat20y = undefined
    }

    this.tag = tag
    this.aggression = aggression
    this.avoid = avoid
    this.offensive = offensive
    this.rebalancedEtf = rebalanced_etf != undefined ? (rebalanced_etf as Array<[string, number]>).map(el => { return el[0] }) : []
    this.replicable = replicable
  }

  get clone() {
    if (!this.replicable) {
      return '' 
    } else if (this.group == 'dynamic' || this.group == 'dynamic_kr') {
      return `/backtest/tactical-allocation?replica=${this.url}`
    } else if (this.group == 'static') {
      return `/backtest/asset-allocation?replica=${this.url}`
    } else if (this.group == 'static_kr') {
      return `/backtest/asset-allocation/korea?replica=${this.url}`
    } else {
      return ''
    }
  }
}

export function makePortfolioUrl(type: string, uuid: string) {
  if (type == 'regular') {
    return `/strategy/${uuid}`
  } else if (type == 'mix') {
    return `/backtest/combine-portfolios?portfolio=${uuid}`
  } else if (type == 'dynamic' || type == 'dynamic_kr') {
    return `/backtest/tactical-allocation?portfolio=${uuid}`
  } else if (type == 'dynamic_mx') {
    return `/backtest/tactical-portfolio-allocation?portfolio=${uuid}`
  } else if (type == 'static_kr') {
    return `/backtest/asset-allocation/korea?portfolio=${uuid}`
  } else {
    return `/backtest/asset-allocation?portfolio=${uuid}`
  }
}

export interface Portfolio {
  url: string
  uuid: string
  title: string
  type: string
  country: 'US' | 'KR' | 'MIX'
  statistics: Statistics
  statistics10: Statistics
  statistics20: Statistics
  rebalancedEtf?: string[]
  request?: RequestJson
  share?: boolean
  alarm?: boolean
  toggle: boolean
  pension?: string
  exchange: string
  tradeDate: number
  reference?: boolean
}

export class Portfolio implements Portfolio {
  constructor(data: any) {
    this.url = makePortfolioUrl(data.type, data.type == 'regular' ? data.url : data.uuid)
    this.uuid = data.uuid
    this.title = data.title
    this.type = data.type
    this.country = data.country
    this.statistics = data.statistics
    this.statistics10 = data.statistics10
    this.statistics20 = data.statistics20
    this.rebalancedEtf = data.rebalanced_etf
    this.request = data.request
    this.share = data.share
    this.alarm = data.alarm
    this.toggle = false
    this.pension = data.pension
    this.exchange = data.exchange
    this.tradeDate = data.trade_date
    this.reference = false
  }
}

export interface ApplicationStrategyRequest {
  url: string
  nameKR: string
  nameUS: string
  youtubeLink: string
  global_usd_url: string
  global_krw_url: string
  korea_url: string
}

export class ApplicationStrategyRequest implements ApplicationStrategyRequest {
  constructor() {
    this.url = ''
    this.nameKR = ''
    this.nameUS = ''
    this.youtubeLink = ''
    this.global_usd_url = ''
    this.global_krw_url = ''
    this.korea_url = ''
  }

  export() {
    this.url = this.url.trim().toLocaleLowerCase()
    const urlTest = /[^a-z0-9-]/
    if (!this.url || urlTest.test(this.url)) {
      throw Error('url 이 잘못됐습니다. 숫자, 알파벳 소문자, - 만 사용가능')
    }
    this.nameKR = this.nameKR.trim()
    if (!this.nameKR) {
      throw Error('한국 이름 누락')
    }
    this.nameUS = this.nameUS.trim()
    if (!this.nameUS) {
      throw Error('영문 이름 누락')
    }
    this.youtubeLink = this.youtubeLink.trim()
    const regex = /^https:\/\/youtu\.be\//
    if (!this.youtubeLink) {
      throw Error('유튜브 링크 누락')
    } else if (!regex.test(this.youtubeLink)) {
      throw Error('유튜브 링크는 https://youtu.be/EJAeALL68iE 와 같은 형태여야합니다.')
    }

    this.global_usd_url = this.global_usd_url.trim()
    this.global_krw_url = this.global_krw_url.trim()
    this.korea_url = this.korea_url.trim()

    if (this.korea_url) {
      const regex = /^https:\/\/snowball72\.com\/backtest\/tactical-allocation\/korea\?portfolio=/

      if (this.global_krw_url || this.global_usd_url) {
        throw Error('한국형 백테스트, 글로벌 백테스트 하나만 골라주세요.')
      } else if (!regex.test(this.korea_url)) {
        throw Error('한국형 전략 주소는 https://snowball72.com/backtest/tactical-allocation/korea?portfolio=XXX~~ 와 같은 형태여야합니다.')
      }
    } else {
      const regex = /^https:\/\/snowball72\.com\/backtest\/tactical-allocation\/global\?portfolio=/

      if (!this.global_usd_url) {
        throw Error('usd 환율 전략 주소 누락')
      } else if (!regex.test(this.global_usd_url)) {
        throw Error('전략 주소는 https://snowball72.com/backtest/tactical-allocation?portfolio=XXX~~ 와 같은 형태여야합니다.')
      }

      if (!this.global_krw_url) {
        throw Error('krw 환율 전략 주소 누락')
      } else if (!regex.test(this.global_krw_url)) {
        throw Error('전략 주소는 https://snowball72.com/backtest/tactical-allocation?portfolio=XXX~~ 와 같은 형태여야합니다.')
      }

      if (this.global_krw_url == this.global_usd_url) {
        throw Error('환율 전략 주소가 서로 같습니다.')
      }
    }

    return {
      url: this.url,
      name_kr: this.nameKR,
      name_us: this.nameUS,
      youtube_link: this.youtubeLink,
      dynamic_usd_pf: this.global_usd_url.split('=')[1],
      dynamic_krw_pf: this.global_krw_url.split('=')[1],
      dynamic_kr_pf: this.korea_url.split('=')[1],
    }
  }
}

export const snowballStrategy = [
  {
    title: '스노우볼 글로벌 EMP',
    type: '공격형',
    sub: '연금저축/ISA용',
    des: '글로벌 자산군을 적극적으로 활용해 높은 수익률을 목표로 하며, 시장 변동성에도 크게 영향을 받지 않는 투자자에게 적합한 상품입니다.',
    cagr: '+10% 이상',
    url: 'ra-global1',
  },
  {
    title: '스노우볼 글로벌 EMP',
    type: '적극투자형',
    sub: '연금저축/ISA용',
    des: '안정적인 수익을 추구하면서도 주식과 채권 등 위험 자산의 비중을 높여 성장 가능성을 극대화하는 전략을 제공합니다. 일정 리스크를 감수할 수 있는 투자자에게 적합합니다.',
    cagr: '+7~9%',
    url: 'ra-global2',
  },
  {
    title: '스노우볼 글로벌 EMP',
    type: '위험중립형',
    sub: '연금저축/ISA용',
    des: '안정성과 수익성을 동시에 추구하는 투자자를 위한 상품입니다. 주식과 안전 자산의 균형을 맞추어 리스크를 적절히 관리하는 전략을 사용합니다.',
    cagr: '+5~7%',
    url: 'ra-global3',
  },
  {
    title: '스노우볼 글로벌 EMP',
    type: '안정추구형',
    sub: '연금저축/ISA용',
    des: '자산 보호를 중요시하면서도 적절한 수익을 추구하는 투자자를 위한 상품으로, 주로 채권과 안전 자산에 비중을 두고 있습니다.',
    cagr: '+3~5%',
    url: 'ra-global4',
  },
  {
    title: '스노우볼 글로벌 EMP',
    type: '안정형',
    sub: '연금저축/ISA용',
    des: '원금 보호를 최우선으로 생각하는 투자자를 위한 상품입니다. 주로 안정적인 채권 및 대체 자산에 투자하여 장기적인 안정성을 추구합니다.',
    cagr: '~3%',
    url: 'ra-global5',
  },
  {
    title: '스노우볼 EMP 퇴직연금',
    type: '공격형',
    sub: 'IRP/퇴직연금DC용',
    des: '퇴직연금 계좌에서도 주식 및 고위험 자산에 적극적으로 투자하며, 장기적인 고수익을 추구하는 공격적인 투자자를 위한 전략입니다.',
    cagr: '+10% 이상',
    url: 'ra-retirement1',
  },
  {
    title: '스노우볼 EMP 퇴직연금',
    type: '적극투자형',
    sub: 'IRP/퇴직연금DC용',
    des: '글로벌 자산군에 균형 잡힌 비율로 투자하며, 높은 수익률을 목표로 하되, 일정 리스크를 감수할 수 있는 투자자에게 적합한 퇴직연금 상품입니다.',
    cagr: '+7~9%',
    url: 'ra-retirement2',
  },
  {
    title: '스노우볼 EMP 퇴직연금',
    type: '위험중립형',
    sub: 'IRP/퇴직연금DC용',
    des: '주식과 채권의 적절한 배분을 통해 안정성을 추구하면서도 수익 기회를 놓치지 않으려는 투자자를 위한 전략입니다. 장기적인 성장이 기대됩니다.',
    cagr: '+5~7%',
    url: 'ra-retirement3',
  },
  {
    title: '스노우볼 EMP 퇴직연금',
    type: '안정추구형',
    sub: 'IRP/퇴직연금DC용',
    des: '퇴직연금 자산의 안전성을 중요시하며, 안정적인 수익을 선호하는 투자자를 위한 상품입니다. 채권 및 안정 자산에 높은 비중을 두고 있습니다.',
    cagr: '+3~5%',
    url: 'ra-retirement4',
  },
  {
    title: '스노우볼 EMP 퇴직연금',
    type: '안정형',
    sub: 'IRP/퇴직연금DC용',
    des: '리스크를 최소화하고, 안정적인 자산운용을 원하는 투자자를 위한 상품입니다. 대부분의 자산을 안정적인 자산에 투자해 장기적인 안정성을 확보합니다.',
    cagr: '~3%',
    url: 'ra-retirement5',
  },
  {
    title: '스노우볼 다이내믹',
    type: '공격형',
    sub: 'IRP/퇴직연금DC용',
    des: '미국 상장 ETF를 활용하여 글로벌 주식 및 고위험 자산에 적극 투자하며, 큰 변동성에도 높은 수익을 기대하는 공격적인 투자자를 위한 상품입니다.',
    cagr: '+10% 이상',
    url: 'ra-dynamic1',
  },
  {
    title: '스노우볼 다이내믹',
    type: '적극투자형',
    sub: '일반계좌용/해외ETF사용',
    des: '주식 및 다양한 글로벌 자산군에 투자하여 높은 수익률을 추구하며, 리스크를 감수할 수 있는 투자자에게 적합한 전략입니다.',
    cagr: '+7~9%',
    url: 'ra-dynamic2',
  },
  {
    title: '스노우볼 다이내믹',
    type: '위험중립형',
    sub: '일반계좌용/해외ETF사용',
    des: '해외 ETF를 통해 주식과 안전 자산을 균형 있게 배분하여 안정성과 수익성을 동시에 추구하는 투자자를 위한 상품입니다.',
    cagr: '+5~7%',
    url: 'ra-dynamic3',
  },
  {
    title: '스노우볼 다이내믹',
    type: '안정추구형',
    sub: '일반계좌용/해외ETF사용',
    des: '미국 상장 ETF를 활용해 안정적인 자산 위주로 투자하며, 적정한 수익을 추구하면서도 리스크를 최소화하려는 투자자에게 적합합니다.',
    cagr: '+3~5%',
    url: 'ra-dynamic4',
  },
  {
    title: '스노우볼 다이내믹',
    type: '안정형',
    sub: '일반계좌용/해외ETF사용',
    des: '주로 미국 상장 안정자산 ETF에 투자하여 원금 보호를 최우선으로 하는 투자자를 위한 상품입니다. 변동성보다 안정성을 중시하는 투자 전략을 제공합니다.',
    cagr: '~3%',
    url: 'ra-dynamic5',
  },
]
