<script setup lang="ts">
import Top from "en/components/Top.vue"
import Footer from 'en/components/Footer.vue'
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue"
import NotificationDialog from "@/components/dialog/NotificationDialog.vue"
import LockDialog from "@/components/dialog/LockDialog.vue"
import { provide, ref } from "vue"

const confirmDialog = ref()
provide("confirmDialog", confirmDialog)

const notificationDialog = ref()
provide("showNotification", (type: string, message: string, confirmCallback: Function) => notificationDialog.value.showNotification(type, message, confirmCallback))

const lockDialog = ref()
provide("showLock", (type: string, message: string) => lockDialog.value.showLock(type, message))

const showDesktopFooter = ref(false)

window.addEventListener('scroll', function() {
    const scrollPosition = window.innerHeight + window.scrollY;
    const pageHeight = document.documentElement.scrollHeight;
    
    if (scrollPosition >= pageHeight * 0.9) {
      showDesktopFooter.value = true
    }
});

</script>
<template>
  <Top />
  <div id="main-content">
    <router-view />
  </div>
  <footer v-if="showDesktopFooter">
    <Footer></Footer>
  </footer>
  <span style="width: 0; height: 0; position: absolute; top: 0; left: 0; color: transparent;">
    <span style="font-weight: 500;" >.</span>
    <span style="font-weight: 600;" >.</span>
    <span style="font-weight: 700;" >.</span>
  </span>
  <ConfirmDialog ref="confirmDialog" />
  <NotificationDialog ref="notificationDialog" />
  <LockDialog ref="lockDialog" />
</template>
<style lang="scss" scoped>
#main-content {
  margin-top: 80px;
  margin-bottom: 50px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  @media (max-width: 987px) {
    display: block;
    margin-top: 56px;
    margin-bottom: 0px;
    width: 100%;
    padding: 0;
    min-height: auto;
  }
}

footer {
  display: flex;
  min-width: 100%;
  justify-content: center;
  background-color: white;
  z-index: 10;

  @media (max-width: 987px) {
    display: none;
  }
}
</style>
