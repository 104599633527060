import axios, { type AxiosError } from "axios"
import App from "en/App.vue"
import router from "en/index"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from "vue"
import vue3GoogleLogin from 'vue3-google-login'

import '@/style/default.css'
import '@/style/locale.scss'
import "@/style/theme.css"
import "@/style/overwrite.scss"

import "primeicons/primeicons.css"
import "primevue/resources/primevue.min.css"

import { refreshTokenAPI } from "core/apis/backtest"
import { useEtfStore } from "core/stores/Etf"
import { usePathStore, useUserStore } from 'core/stores/User'
import PrimeVue from "primevue/config"
import ConfirmationService from 'primevue/confirmationservice'

import en from "core/locale/en.json"
import { createI18n } from "vue-i18n"

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

router.beforeEach(async (to, from) => {
  const isLogin = await userStore.autoLogin()
  if (to.meta.authRequired && !userStore.loggedInUser) {
    if (!isLogin) {
      pathStore.setPathOfBeforeLogin(to.path)
      return '/user/login'
    }
  }

  const metaTitle: any = document.querySelector('head meta[name="title"]')
  const metaDescription: any = (document.querySelector('head meta[name="description"]') as any)

  if (to.meta.description && to.meta.title) {
    const title = `ETF Portfolio - ${to.meta.title}`
    document.title = title
    metaTitle.content = title
    metaDescription.content = to.meta.description
  } else if (to.name == 'StrategyResult' || to.name == 'EtfDetail') {
    // pass
  } else {
    document.title = 'ETF Portfolio - Best Portfolio Backtesting Tool'
    metaTitle.content = 'ETF Portfolio - Best Portfolio Backtesting Tool'
    metaDescription.content = "ETF Portfolio provides a portfolio analysis tool for backtesting tactical asset allocation and optimization. It allows you to evaluate historical performance and validate asset allocation strategies."
  }
})

const i18n = createI18n({
  locale: 'en',
  messages: {
    en
  },
  legacy: false,
})

const app = createApp(App).use(pinia).use(router).use(PrimeVue).use(ConfirmationService).use(i18n)

if (import.meta.env.PROD) {
  app.use(vue3GoogleLogin, {
    clientId: '1028571062622-1bv4m0m0nrj8fkq225fhb198evehhvv1.apps.googleusercontent.com'
  })
}

axios.defaults.withCredentials = true
axios.interceptors.response.use((response) => {
  return response
}, handleAxiosError)

async function handleAxiosError(err: AxiosError) {
  if (err.response) {
    const code = err.response.data.code
    if (code == "4010") {
      alert("Invalid login.\n error code 4010")
      await userStore.logout()
      router.push("/user/login")
      return Promise.reject(err)
    } else if (code == "4011") {
      await refreshTokenAPI()
      const res = await axios.request(err.response.config)
      return res
    } else if (code == "4012") {
      await userStore.logout()
      router.push("/user/login")
      return Promise.reject(false)
    } else {
      return Promise.reject(err)
    }
  } else {
    return Promise.reject(err)
  }
}

const pathStore = usePathStore()

const userStore = useUserStore()

const etfStore = useEtfStore()
etfStore.fetchEtfList()

app.mount("#app")
